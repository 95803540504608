import axios from "axios";
import { toast } from "react-toastify";

const serverUrl = "https://pro-wellness.ru:9443";

export async function fetchData() {
  try {
    const response = await axios.get(
      serverUrl + "/api/projects?component=*&populate=deep"
    );
    // console.log(response);
    // debugger;
    // setProjects(getProjects(response.data.data[0].attributes.project));

    return get(response.data.data[0].attributes.project);
  } catch (error) {
    toast.error(error.message);
    return false;
  }
}

function get(arr) {
  const projectsAll = arr.map((item, index) => {
    const project = item;
    return {
      id: project.id,
      title: project.title,
      description: project.description,
      baner: serverUrl + project.baner.data.attributes.url,
      banerAlt: project.baner.data.attributes.alternativeText,
      city: project.characteristic.city,
      year: project.characteristic.year,
      stage: project.characteristic.stage,
      projectDescription: project.projectDescription,
      list: project.list,
      topPhoto: serverUrl + project.detail.topPhoto.data.attributes.url,
      topPhotoAlt: project.detail.topPhoto.data.attributes.alternativeText,
      bottomPhoto: serverUrl + project.detail.bottomPhoto.data.attributes.url,
      bottomPhotoAlt:
        project.detail.bottomPhoto.data.attributes.alternativeText,
      otherPhoto: project.otherPhotos.otherPhotos.data,
    };
  });
  return projectsAll;
}

export function addServerPath(shortPath) {
  return serverUrl + shortPath;
}
