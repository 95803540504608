/* eslint-disable no-unused-vars */
import pict_1_1920 from "assets/img/ConceptPage/slider/slider1_1920.jpg";
import pict_1_1280 from "assets/img/ConceptPage/slider/slider1_1280.jpg";
import pict_1_360 from "assets/img/ConceptPage/slider/slider1_360.jpg";
import pict_2_1920 from "assets/img/ConceptPage/slider/slider2_1920.jpg";
import pict_2_1280 from "assets/img/ConceptPage/slider/slider2_1280.jpg";
import pict_2_360 from "assets/img/ConceptPage/slider/slider2_360.jpg";
import pict_3_1920 from "assets/img/ConceptPage/slider/slider3_1920.jpg";
import pict_3_1280 from "assets/img/ConceptPage/slider/slider3_1280.jpg";
import pict_3_360 from "assets/img/ConceptPage/slider/slider3_360.jpg";
import pict_4_1920 from "assets/img/ConceptPage/slider/slider4_1920.jpg";
import pict_4_1280 from "assets/img/ConceptPage/slider/slider4_1280.jpg";
import pict_4_360 from "assets/img/ConceptPage/slider/slider4_360.jpg";
import { useEffect, useState } from "react";

import styles from "./styles.module.scss"; // На уровень выше

const Slider = () => {
  const sliderElements = [
    {
      srcSet: [
        { src: pict_1_360, media: "(max-width: 360px)" },
        { src: pict_1_1280, media: "(max-width: 1280px)" },
      ],
      src: pict_1_1920,
      alt: "Концепция",
    },
    {
      srcSet: [
        { src: pict_2_360, media: "(max-width: 360px)" },
        { src: pict_2_1280, media: "(max-width: 1280px)" },
      ],
      src: pict_2_1920,
      alt: "Концепция",
    },
    {
      srcSet: [
        { src: pict_3_360, media: "(max-width: 360px)" },
        { src: pict_3_1280, media: "(max-width: 1280px)" },
      ],
      src: pict_3_1920,
      alt: "Концепция",
    },
    {
      srcSet: [
        { src: pict_4_360, media: "(max-width: 360px)" },
        { src: pict_4_1280, media: "(max-width: 1280px)" },
      ],
      src: pict_4_1920,
      alt: "Концепция",
    },
  ];

  var counterPhotos = 0;
  const [isPhotosUploaded, setIsPhotosUploaded] = useState(false);
  const handlePhotoLoad = () => {
    if (++counterPhotos === sliderElements.length) setIsPhotosUploaded(true);
  };

  const [currentImage, setCurrentImage] = useState(0);
  // Этот useEffect крутит слайдер по кругу
  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       setCurrentImage((prevImage) => (prevImage + 1) % sliderElements.length);
  //     }, 3000);
  //     return () => clearInterval(interval);
  //   }, []);

  // Этот useEffect сменит на 1 раз все фото, и остановится.
  useEffect(() => {
    const interval = setInterval(() => {
      if (currentImage < sliderElements.length - 1) {
        setCurrentImage((prevImage) =>
          prevImage !== undefined ? prevImage + 1 : 0
        );
      } else {
        clearInterval(interval);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [currentImage, isPhotosUploaded, sliderElements.length]);

  return (
    <div className={styles.sliderWrapper}>
      {sliderElements.map((element, index) => (
        <picture key={index}>
          {element.srcSet.map((item, index) => (
            <source key={index} srcSet={item.src} media={item.media} />
          ))}
          <img
            onContextMenu={(e) => e.preventDefault()}
            src={element.src}
            alt={element.alt}
            style={{
              position: "absolute",
              opacity: currentImage === index ? 1 : 0,
              transition: "opacity 2.0s",
              objectPosition: "center",
              objectFit: "cover",
              overflow: "hidden",
              height: "100%",
              width: "100%",
            }}
            onLoad={handlePhotoLoad}
          />
        </picture>
      ))}
    </div>
  );
};

export default Slider;
