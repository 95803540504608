import { getRowsPhoto } from "utils/getRowsPhoto";
import AdaptablePicture from "./AdaptablePicture";

import styles from "./styles.module.scss";
import { addServerPath } from "api/api";

const ShowAllPhotos = ({ photos }) => {
  const complete = photos.map((item) => {
    return {
      srcSet: [
        {
          src: addServerPath(item.attributes.url),
          media: "(min-width: 1024px)",
        },
      ],
      // src: serverUrl + item.attributes.url,
      src: addServerPath(item.attributes.url),
      alt: item.attributes.alternativeText,
      name: item.attributes.name,
    };
  });

  // complete.sort((a, b) => {
  //   if (a.name < b.name) return -1;
  //   if (a.name > b.name) return 1;
  //   return 0;
  // });

  const rows = getRowsPhoto(complete);

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        {rows.map((row, index) => {
          return row.length === 1 ? (
            <div key={index} className={styles.rowOne}>
              <AdaptablePicture element={row[0]} />
            </div>
          ) : (
            <div key={index} className={styles.rowTwo}>
              <AdaptablePicture element={row[0]} />
              <AdaptablePicture element={row[1]} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ShowAllPhotos;
