import { Route, Routes } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AboutPage, { routeMain as routeAboutPage } from "pages/AboutPage";
import ContactsPage, {
  routeMain as routeContactsPage,
} from "pages/ContactsPage";
import MainPage from "pages/MainPage";
import NotFoundPage, {
  routeMain as routeNotFoundPage,
} from "pages/NotFoundPage";
import ConceptPage, { routeMain as routeConceptPage } from "pages/СonceptPage";
import ProjectPage, { routeMain as routeProjectPage } from "pages/ProjectPage";
import ArchitecturePage, {
  routeMain as routeArchitecturePage,
} from "pages/ArchitecturePage";

import Header from "components/Header";
import Footer from "components/Footer";

import styles from "./styles.module.scss";

const AppContent = () => {
  return (
    <div className={styles.wrapper}>
      <ToastContainer
        position="top-right"
        autoClose={500000}
        limit={10}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        theme="dark"
      />
      <Header />
      <main className={styles.mainWrapper}>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path={routeAboutPage()} element={<AboutPage />} />
          <Route path={routeContactsPage()} element={<ContactsPage />} />
          <Route path={routeConceptPage()} element={<ConceptPage />} />
          <Route path={routeNotFoundPage()} element={<NotFoundPage />} />
          <Route path={routeProjectPage() + "/:id"} element={<ProjectPage />} />
          <Route
            path={routeArchitecturePage()}
            element={<ArchitecturePage />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export default AppContent;
