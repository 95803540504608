import { Link } from "react-router-dom";
import { routeMain as routeAboutPage } from "pages/AboutPage";
import { routeMain as routeArchitecturePage } from "pages/ArchitecturePage";
import { routeMain as routeConceptPage } from "pages/СonceptPage";
import { routeMain as routeContactsPage } from "pages/ContactsPage";

import logo_black from "assets/img/logo_Black_letters.svg";

import styles from "./styles.module.scss";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className={styles.root}>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.first}>
            <img src={logo_black} alt="Логотип" />
            <p className={styles.entitlement}>Все права защищены © 2023</p>
            <a
              href="https://deviart.group/"
              className={styles.deviart}
              target="blank"
            >
              Сделано в Deviart
            </a>
          </div>
          <div className={styles.menus}>
            <div className={styles.second}>
              <nav>
                <ul className={styles.list}>
                  <li>
                    <Link to={routeAboutPage()} className={styles.item}>
                      О НАС
                    </Link>
                  </li>
                  <li>
                    <Link to={routeArchitecturePage()} className={styles.item}>
                      АРХИТЕКТУРА
                    </Link>
                  </li>
                  <li>
                    <Link to={routeConceptPage()} className={styles.item}>
                      КОНЦЕПЦИЯ
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className={styles.third}>
              <Link to={routeContactsPage()} className={styles.contacts}>
                КОНТАКТЫ
              </Link>
              <ul className={styles.listContacts}>
                <li>
                  <a
                    className={styles.itemContacts}
                    href="tel:+79251800099"
                    target="blank"
                  >
                    +7 (925) 180 00 99
                  </a>
                </li>
                <li>
                  <a
                    className={styles.itemContacts}
                    href="https://t.me/story_prowellness"
                    target="blank"
                  >
                    @story_prowellness
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className={styles.fourth}>
            <button className={styles.goTop} onClick={scrollToTop}>
              НАВЕРХ
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
