import React, { useState } from "react";
import { Link } from "react-router-dom";
import SocialBlock from "./SocialBlock";
import NavBlock from "./NavBlock";
import Logotip from "./Logotip";
import burger_black_line from "assets/img/burger_Black_line_3.svg";
import { routeMain as routeMainPage } from "pages/MainPage";
import DropDown from "./DropDown";
import styles from "./styles.module.scss";

const Header = () => {
  const [modalMenu, setModalMenu] = useState(false);

  return (
    <header className={styles.root}>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.navBlock}>
            <NavBlock />
          </div>
          <div className={styles.logo}>
            <Link to={routeMainPage()}>
              <Logotip className={styles.Logotip} />
            </Link>
          </div>
          <div className={styles.right}>
            <div className={styles.socialBlock}>
              <SocialBlock />
            </div>

            <button
              type="button"
              className={styles.burger}
              onClick={(e) => {
                e.stopPropagation();
                setModalMenu(true);
              }}
            >
              <img src={burger_black_line} alt="Кнопка бургера" />
            </button>
          </div>
        </div>
      </div>
      {modalMenu && <DropDown onClose={() => setModalMenu(false)} />}
    </header>
  );
};

export default Header;
