import ModalWindow from "components/ServiceСomponents/ModalWindow";
import SocialBlock from "../SocialBlock";
import NavBlock from "../NavBlock";

import styles from "./styles.module.scss";
import { useEffect, useRef } from "react";

const DropDown = ({ onClose }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    function handleClick(event) {
      const clickedElement = event.target;
      if (clickedElement.tagName === "A") {
        onClose(false);
      }
    }

    const modalElement = modalRef.current;
    modalElement.addEventListener("click", handleClick);

    return () => {
      modalElement.removeEventListener("click", handleClick);
    };
  }, [onClose]);

  return (
    <ModalWindow onClose={onClose}>
      <div className={styles.wrapper} ref={modalRef}>
        <div className={styles.navBlock}>
          <NavBlock />
        </div>
        <div className={styles.social}>
          <SocialBlock />
        </div>
      </div>
    </ModalWindow>
  );
};

export default DropDown;
