import { NavLink } from "react-router-dom";
import { routeMain as routeAboutPage } from "pages/AboutPage";
import { routeMain as routeContactsPage } from "pages/ContactsPage";
import { routeMain as routeConceptPage } from "pages/СonceptPage";

import { routeMain as routeArchitecturePage } from "pages/ArchitecturePage";

import styles from "./styles.module.scss";

const NavBlock = () => {
  return (
    <nav className={styles.nav}>
      <ul className={styles.menu}>
        <li>
          <NavLink to={routeAboutPage()} className={styles.item}>
            О НАС
          </NavLink>
        </li>

        <li>
          <NavLink to={routeArchitecturePage()} className={styles.item}>
            АРХИТЕКТУРА
          </NavLink>
        </li>
        <li>
          <NavLink to={routeConceptPage()} className={styles.item}>
            КОНЦЕПЦИЯ
          </NavLink>
        </li>
        <li>
          <NavLink to={routeContactsPage()} className={styles.item}>
            КОНТАКТЫ
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default NavBlock;
