import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

const ServiceLine = ({ tip, description, link }) => {
  return (
    <div className={styles.container}>
      <div className={styles.line}>
        <div className={styles.tip}>{tip}</div>
        <div className={`${styles.description} ${styles.show1}`}>
          {description}
        </div>

        <Link to={link()} className={styles.request}>
          ПОДРОБНЕЕ
        </Link>
      </div>
      <div className={`${styles.description} ${styles.show2}`}>
        {description}
      </div>
    </div>
  );
};

export default ServiceLine;
