import routeMain from "./routes";
import location from "assets/img/ProjectPage/svg/location.svg";
import calendar from "assets/img/ProjectPage/svg/calendar.svg";
import tools from "assets/img/ProjectPage/svg/tools.svg";
import { routeMain as routeNotFoundPage } from "pages/NotFoundPage";
import { routeMain as routeProjectPage } from "pages/ProjectPage";

import { useContext, useEffect, useRef, useState } from "react";
import ShowAllPhotos from "./ShowAllPhotos";
// import Spiner from "components/ServiceСomponents/Spiner";
import { AppContext } from "components/AppContext/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import { fetchData } from "api/api";

import styles from "./styles.module.scss";

const ProjectPage = () => {
  const { projects, setProjects } = useContext(AppContext);

  const containerRef = useRef(null);
  const [offset, setOffset] = useState("0px");
  const [showAllPhotos, setShowAllPhotos] = useState(false);
  const [index, setIndex] = useState(null);
  const [currentProject, setCurrentProject] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [forwardButtonDisabled, setForwardButtonDisabled] = useState(false);
  const [backButtonDisabled, setBackButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      const rezult = await fetchData();
      if (rezult) {
        setProjects(rezult);
      }
    };

    if (projects.length === 0) {
      fetchProjects();
    } else {
      const ind = projects.findIndex((project) => project.id === +id);
      if (ind === -1) {
        navigate(routeNotFoundPage());
      } else {
        setForwardButtonDisabled(ind === projects.length - 1 ? true : false);
        setBackButtonDisabled(ind === 0 ? true : false);
        setIndex(ind);
        setCurrentProject(projects[ind]);

        setIsLoading(false);
      }
    }
  }, [id, navigate, projects, setProjects]);

  const handlerForwardButton = (e) => {
    e.stopPropagation();
    const newId = projects[index + 1].id;
    navigate(routeProjectPage() + `/${newId}`);
    scrollToTop();
    setShowAllPhotos(false);
  };

  const handlerBackButton = (e) => {
    e.stopPropagation();
    const newId = projects[index - 1].id;
    navigate(routeProjectPage() + `/${newId}`);
    scrollToTop();
    setShowAllPhotos(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const { left, right } = containerRef.current.getBoundingClientRect();
        setOffset({ left, right });
      }
    };
    handleResize(); // Вызываем функцию при монтировании компонента
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isLoading]);

  return isLoading ? (
    // <Spiner />
    <></>
  ) : (
    <section
      className={styles.root}
      style={{
        "--distance-to-the-container-far": `${offset.right}px`,
        "--distance-to-the-container": `${offset.left}px`,
        "--width-container": `${offset.right - offset.left}px`,
        // "--distance-to-the-container-double": `${offset.left * 2}px`,
      }}
    >
      <div ref={containerRef} className="container">
        <div className={styles.headerWrapper}>
          <button
            type="button"
            className={`${styles.back} ${styles.direction} ${styles.margins}`}
            onClick={handlerBackButton}
            disabled={backButtonDisabled}
          >
            НАЗАД
          </button>
          <h1 className={`${styles.title} ${styles.showTitle1}`}>
            {currentProject.title}
          </h1>
          <button
            type="button"
            className={`${styles.forward} ${styles.direction}`}
            onClick={handlerForwardButton}
            disabled={forwardButtonDisabled}
          >
            ВПЕРЕД
          </button>
        </div>
        <div className="container">
          <h1 className={`${styles.title} ${styles.showTitle2}`}>
            {currentProject.title}
          </h1>
        </div>
      </div>

      <img
        onContextMenu={(e) => e.preventDefault()}
        src={currentProject.topPhoto}
        className={`${styles.pictTop} ${styles.pict}`}
        alt={currentProject.topPhotoAlt}
      />

      <div className="container">
        <div className={styles.tablet}>
          <div className={styles.tabletLeft}>
            <div className={styles.property}>
              <img src={location} alt="location svg" />
              <p>{currentProject.city}</p>
            </div>
            <div className={styles.property}>
              <img src={calendar} alt="year svg" />

              <p>{currentProject.year}</p>
            </div>
            <div className={styles.property}>
              <img src={tools} alt="tools svg" />
              <p>Стадия проекта: {currentProject.stage}</p>
            </div>
          </div>
          <div className={styles.tabletRight}>
            {currentProject.projectDescription.map((item) => {
              return item.paragraph.trim() !== "" ? (
                <p key={item.id}>{item.paragraph}</p>
              ) : null;
            })}
            {currentProject.list.length > 0 && (
              <ul type="disc" className={styles.descriptionList}>
                {currentProject.list.map((item) => {
                  return item.item.trim() !== "" ? (
                    <li key={item.id}>{item.item}</li>
                  ) : null;
                })}
              </ul>
            )}
          </div>
        </div>
      </div>

      <img
        onContextMenu={(e) => e.preventDefault()}
        src={currentProject.bottomPhoto}
        className={`${styles.pictBottom} ${styles.pict}`}
        alt={currentProject.bottomPhotoAlt}
      />

      <button
        className={styles.showButton}
        onClick={() => {
          setShowAllPhotos(!showAllPhotos);
        }}
      >
        {showAllPhotos ? "скрыть фото" : "Посмотреть все фото"}
      </button>

      {showAllPhotos && <ShowAllPhotos photos={currentProject.otherPhoto} />}

      <div className="container">
        <div
          className={`${styles.flipWrapper} ${
            showAllPhotos ? styles.flipWrapperShow : ""
          }`}
        >
          <button
            type="button"
            className={`${styles.back} ${styles.direction}`}
            onClick={handlerBackButton}
            disabled={backButtonDisabled}
          >
            НАЗАД
          </button>

          <button
            type="button"
            className={`${styles.forward} ${styles.direction}`}
            onClick={handlerForwardButton}
            disabled={forwardButtonDisabled}
          >
            ВПЕРЕД
          </button>
        </div>
      </div>
    </section>
  );
};

export { routeMain };
export default ProjectPage;
