import routeMain from "./routes";
import HeroSection from "./HeroSection";
import PortfolioSection from "./PortfolioSection";
import AboutSection from "./AboutSection";
import ServicesSection from "./ServicesSection";
import "./styles.module.scss";
import { useEffect } from "react";

const MainPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeroSection />
      <PortfolioSection />
      <AboutSection />
      <ServicesSection />
    </>
  );
};

export { routeMain };
export default MainPage;
