import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";
import { routeMain as routeProjectPage } from "pages/ProjectPage";

import Card from "./Card";

const Tree = ({ list, index = 0 }) => {
  const count = list.filter((item) => item !== undefined).length;

  if (index % 2 !== 0) list.reverse();
  const navigate = useNavigate();
  const handleClick = (event) => {
    event.preventDefault();
    const id = +event.currentTarget.dataset.id;
    navigate(routeProjectPage() + `/${id}`); // Перенаправляем пользователя на другую страницу
  };

  return count === 1 ? (
    <Card
      id={list[0]?.id}
      src={list[0].baner}
      stylesPict={"big"}
      alt={list[0].banerAlt}
      onClick={handleClick}
      title={list[0].title}
      description={list[0].description}
      index={index}
    />
  ) : count === 2 ? (
    <div className={styles.twoBig}>
      <Card
        id={list[0]?.id}
        src={list[0].baner}
        stylesPict={"big"}
        alt={list[0].banerAlt}
        onClick={handleClick}
        title={list[0].title}
        description={list[0].description}
        index={index}
      />

      <Card
        id={list[1]?.id}
        src={list[1].baner}
        stylesPict={"big"}
        alt={list[1].banerAlt}
        onClick={handleClick}
        title={list[1].title}
        description={list[1].description}
        index={index}
      />
    </div>
  ) : (
    <div className={`${styles.trio} ${index % 2 !== 0 ? styles.reverse : ""}`}>
      <Card
        id={list[0]?.id}
        src={list[0].baner}
        stylesPict={"big"}
        alt={list[0].banerAlt}
        onClick={handleClick}
        title={list[0].title}
        description={list[0].description}
        index={index}
      />

      <div
        className={`${styles.two} ${index % 2 !== 0 ? styles.twoReverse : ""}`}
      >
        <Card
          id={list[1]?.id}
          src={list[1].baner}
          stylesPict={"smol"}
          alt={list[1].banerAlt}
          onClick={handleClick}
          title={list[1].title}
          description={list[1].description}
          index={index}
        />

        <Card
          id={list[2].id}
          src={list[2].baner}
          stylesPict={"smol"}
          alt={list[2].banerAlt}
          onClick={handleClick}
          title={list[2].title}
          description={list[2].description}
          index={index}
        />
      </div>
    </div>
  );
};

export default Tree;
