import { routeMain as routeArchitecturePage } from "pages/ArchitecturePage";
import { Link } from "react-router-dom";
import Tree from "./Three";
import { useContext, useEffect, useState } from "react";

import { AppContext } from "components/AppContext/AppContext";
import styles from "./styles.module.scss";
import { fetchData } from "api/api";
// import Spiner from "components/ServiceСomponents/Spiner";

const PortfolioSection = () => {
  const { projects, setProjects } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProjects = async () => {
      const rezult = await fetchData();
      if (rezult) {
        setProjects(rezult);
      }
    };

    if (projects.length === 0) {
      fetchProjects();
    } else {
      setIsLoading(false);
    }
  }, [projects, setProjects]);

  return isLoading ? (
    // <Spiner />
    <></>
  ) : (
    <section className={styles.root}>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.performance}>
            {/* <div className={styles.left}> */}
            <p className={styles.works}>НАШИ ВЫПОЛНЕННЫЕ РАБОТЫ</p>
            <Link to={routeArchitecturePage()}>
              <h2 className={styles.title}>КОМПЛЕКСНОЕ ПРОЕКТИРОВАНИЕ</h2>
            </Link>
            <Link
              to={routeArchitecturePage()}
              className={`${styles.get} ${styles.right}`}
            >
              ВСЕ РАБОТЫ
            </Link>
          </div>
          <div className={styles.outputThree}>
            <Tree list={projects} />
          </div>

          <Link
            to={routeArchitecturePage()}
            className={`${styles.get} ${styles.down}`}
          >
            ВСЕ РАБОТЫ
          </Link>
        </div>
      </div>
    </section>
  );
};

export default PortfolioSection;
