const AdaptablePicture = ({ element }) => {
  return (
    <picture>
      {element.srcSet.map((item, index) => (
        <source key={index} srcSet={item.src} media={item.media} />
      ))}
      <img
        onContextMenu={(e) => e.preventDefault()}
        src={element.src}
        alt={element.alt}
      />
    </picture>
  );
};

export default AdaptablePicture;
