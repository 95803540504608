import styles from "./styles.module.scss";
import person1 from "assets/img/aboutPage/persons/person1.jpg";
import person2 from "assets/img/aboutPage/persons/person2.jpg";
import person3 from "assets/img/aboutPage/persons/person3.jpg";
import Person from "./Person";
import { useState } from "react";

const Persons = () => {
  const units = [
    {
      photo: person1,
      firstName: "Людмила",
      lastName: "Львовская",

      li: [
        "Бакалавриат в Управлении предприятием; в Управлении гостиничным хозяйством и санаторием.",
        "Бакалавр архитектуры кафедра Дизайн Архитектурной Среды.",
        "МБА Управление проектами.",
        "Эксперт в области управления проектами с 2003 года",
      ],
    },
    {
      photo: person2,
      firstName: "Анастасия",
      lastName: "Берг",
      li: [
        "РНИМУ им. Пирогова. Клиническая психология;",
        "РАНХиГС, ЕМВА, стратегический менеджмент (стажировка в Гарварде)",
        "РАНХиГС — организационное консультирование, создание концепций.",
        "Государственный Университет Управления — аспирантура, кафедра управления знаниями",
      ],
    },
    {
      photo: person3,
      firstName: "Иван",
      lastName: "Чернышов",
      li: [
        'Архитектор, кафедра "Дизайн Архитектурной Среды" (2005)',
        "С 2006 года ГАП в проектировании велнес объектов. Концепции, функционально-планировочные решения, технологии",
      ],
    },
  ];

  const [leftButtonLock, setLeftButtonLock] = useState(false);
  const [rightButtonLock, setRightButtonLock] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleLeftButton = (e) => {
    e.stopPropagation();
    if (currentIndex > 0) {
      if (currentIndex - 1 === 0) setLeftButtonLock(true);
      setCurrentIndex(currentIndex - 1);
      setRightButtonLock(false);
    }
  };

  const handleRightButton = (e) => {
    e.stopPropagation();
    if (currentIndex < units.length - 1) {
      if (currentIndex + 1 === units.length - 1) setRightButtonLock(true);
      setCurrentIndex(currentIndex + 1);
      setLeftButtonLock(false);
    }
  };

  return (
    <>
      {/* <div className={styles.persons}> */}
      <ul className={styles.persons}>
        {units.map((item, index) => (
          <li>
            <Person key={index} unit={item} />
          </li>
        ))}
      </ul>
      {/* </div> */}

      <div className={styles.scrollPersons}>
        <Person unit={units[currentIndex]} />
        <div className={styles.buttonsArow}>
          <button className={styles.buttonLeft} onClick={handleLeftButton}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="16"
              viewBox="0 0 26 16"
              fill="transparent"
            >
              <rect width="100%" height="100%" fill="white" />
              <path
                className={`${leftButtonLock ? styles.buttonLock : ""}`}
                d="M25.001 8.07422H1.00098"
                stroke="#565656"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="red"
              />
              <path
                className={`${leftButtonLock ? styles.buttonLock : ""}`}
                d="M8 15L1 8L8 1"
                stroke="#565656"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <button className={styles.buttonRight} onClick={handleRightButton}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="16"
              viewBox="0 0 26 16"
              fill="none"
            >
              <rect width="100%" height="100%" fill="white" />
              <path
                className={`${rightButtonLock ? styles.buttonLock : ""}`}
                d="M1.00098 8L25.001 7.9948"
                stroke="#565656"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                className={`${rightButtonLock ? styles.buttonLock : ""}`}
                d="M18.001 1L25.001 8L18.001 15"
                stroke="#565656"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

export default Persons;
