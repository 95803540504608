import whatsUp_gold from "assets/social-icons/whatsUp_gold.svg";
import pinterest_gold from "assets/social-icons/pinterest_gold.svg";
import instagram_gold from "assets/social-icons/instagram_gold.svg";
import telegram_gold from "assets/social-icons/telegram_gold.svg";
import styles from "./styles.module.scss";

const SocialBlock = () => {
  return (
    <ul className={styles.social}>
      <li>
        <a
          href="https://api.whatsapp.com/send?phone=79251800099"
          target="blank"
        >
          <img src={whatsUp_gold} alt="whatsUp icon" />
        </a>
      </li>
      <li>
        <img src={pinterest_gold} alt="pinterest icon" />
      </li>
      <li>
        <img src={instagram_gold} alt="instagram icon" />
      </li>
      <li>
        <a href="https://t.me/story_prowellness" target="blank">
          <img src={telegram_gold} alt="telegram icon" />
        </a>
      </li>
    </ul>
  );
};

export default SocialBlock;
