import styles from "./styles.module.scss";
const Concept = ({ unit }) => {
  return (
    <div className={styles.concept}>
      <h2 className={styles.title}>{unit.title}</h2>
      <ul className={styles.list}>
        {unit.li.map((item, index) => (
          <li className={styles.item} key={index}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Concept;
