import { useState } from "react";
import Concept from "./Concept";

import styles from "./styles.module.scss";

const ConceptsBlock = () => {
  const concepts = [
    {
      title: "БАЗОВАЯ КОНЦЕПЦИЯ",
      li: [
        "Основная идея создаваемого бизнеса. Концепция и философия будущего Объекта. Описание целей и задач, предполагаемых к решению в рамках Проекта: какие задачи клиента будут решать создаваемые бизнес процессы",
        "Актуальность и соответствие Объекта/Проекта планируемой бизнес идее: достаточность мощностей комплекса с точки зрения потока клиентов и портфеля продуктов. Равно, как и достаточность площадей, соответствие конструкции, устройство логичных путей внутри Объекта и на территории. Возможность устройства различных последовательных ТХ процессов и обеспечение контроля управления и доступа группами лиц",
        "Разработка планировочного решения с учетом требуемых технологических процессов, действующих требований и нормативов",
      ],
    },
    {
      title: "СТАНДАРТНАЯ КОНЦЕПЦИЯ",
      li: [
        "Базовая концепция",
        "Создание технологической модели предприятия",
        "Создание бизнес модели предприятия с точки зрения жизнедеятельности Объекта",
        "Формирование пакета продуктов или медико-технологического задания",
        "Для предприятий здравоохранения предлагается разработка медицинской или санаторно-курортной концепции предприятия. В настоящее время есть определенное сегментирование на узкоспециализированые предприятия с понятными предложениями для клиента",
        "Формирование административного и операционного пакета",
      ],
    },
    {
      title: "БИЗНЕС КОНЦЕПЦИЯ",
      li: [
        "Стандартная концепция",
        "Разработка технологических последовательных процессов и подготовка ТЗ для смежных проектных организаций",
        "Технико-экономическое обоснование методик и решений в рамках выбранной концепции",
        "Формирование визуальной концепции (без ее проработки) Объекта и бренда предприятия; маркетингового имиджа предприятия и предлагаемых им продуктов",
      ],
    },
  ];

  const [leftButtonLock, setLeftButtonLock] = useState(false);
  const [rightButtonLock, setRightButtonLock] = useState(false);
  const [currentConcept, setCurrentConcept] = useState(0);

  const handleLeftButton = (e) => {
    e.stopPropagation();
    if (currentConcept > 0) {
      if (currentConcept - 1 === 0) setLeftButtonLock(true);
      setCurrentConcept(currentConcept - 1);
      setRightButtonLock(false);
    }
  };

  const handleRightButton = (e) => {
    e.stopPropagation();
    if (currentConcept < concepts.length - 1) {
      if (currentConcept + 1 === concepts.length - 1) setRightButtonLock(true);
      setCurrentConcept(currentConcept + 1);
      setLeftButtonLock(false);
    }
  };

  return (
    <>
      <div className={styles.bottomBlock}>
        {concepts.map((item, index) => (
          <Concept key={index} unit={item} />
        ))}
      </div>
      <div className={styles.conceptsShow}>
        <Concept unit={concepts[currentConcept]} className={styles.unit} />
        <div className={styles.buttonsArow}>
          <button className={styles.buttonLeft} onClick={handleLeftButton}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="16"
              viewBox="0 0 26 16"
              fill="transparent"
            >
              <rect width="100%" height="100%" fill="white" />
              <path
                className={`${leftButtonLock ? styles.buttonLock : ""}`}
                d="M25.001 8.07422H1.00098"
                stroke="#565656"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="red"
              />
              <path
                className={`${leftButtonLock ? styles.buttonLock : ""}`}
                d="M8 15L1 8L8 1"
                stroke="#565656"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <button className={styles.buttonRight} onClick={handleRightButton}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="16"
              viewBox="0 0 26 16"
              fill="none"
            >
              <rect width="100%" height="100%" fill="white" />
              <path
                className={`${rightButtonLock ? styles.buttonLock : ""}`}
                d="M1.00098 8L25.001 7.9948"
                stroke="#565656"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                className={`${rightButtonLock ? styles.buttonLock : ""}`}
                d="M18.001 1L25.001 8L18.001 15"
                stroke="#565656"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

export default ConceptsBlock;
