export function getRowsPhoto(arr) {
  const rows = [];
  let i = 0,
    j = 0;
  while (j <= arr.length - 1) {
    if (i % 2 === 0) {
      rows[i] = [arr[j]];
      i++;
      j++;
    } else {
      if (arr.length - 1 >= j + 1) {
        rows[i] = [arr[j], arr[j + 1]];
        i += 1;
        j += 2;
      } else {
        rows[i] = [arr[j]];
        i++;
        j++;
      }
    }
  }
  return rows;
}
