import routeMain from "./routes";
import { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import Slider from "./Slider";

import ConceptsBlock from "./ConceptsBlock";

const ConceptPage = () => {
  const containerRef = useRef(null);
  const [offset, setOffset] = useState("0px");

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const { left, right } = containerRef.current.getBoundingClientRect();
        setOffset({ left, right });
      }
    };
    handleResize(); // Вызываем функцию при монтировании компонента
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section
      className={styles.root}
      style={{
        "--distance-to-the-container-far": `${offset.right}px`,
        "--distance-to-the-container": `${offset.left}px`,
        "--width-container": `${offset.right - offset.left}px`,
        "--distance-to-the-container-double": `${offset.left * 2}px`,
      }}
    >
      <div ref={containerRef} className="container">
        <div className={styles.performance1}>
          <h1 className={styles.title1}>
            <span>ТРИ ШАГА</span>
            <em className={styles.essence}>УСПЕХА ПРОЕКТА</em>
          </h1>
          <p className={styles.epilogue1}>
            Комплексное проектирование и формирование курортов, объектов
            оздоровления, восстановления и активного долголетия
          </p>
        </div>
      </div>

      <Slider />

      <div className="container">
        <div className={styles.midlBlock}>
          <p className={styles.midlLeft}>
            Комплексное проектирование курортного объекта для отдыха, рекреации
            и оздоровления успешно в случае тщательной проработки трех
            идеологически важных и рассчитанных шагов.
          </p>
          <div className={styles.midlRight}>
            <ul>
              <li>
                <p>
                  Сформированная концепция жизнедеятельности предприятия с
                  учетом современных технологий, требований и норм.
                </p>
                <p>
                  Разработка портфеля продуктов и экономическое обоснование
                  проекта.
                </p>
                <p>
                  Маркетинг. Солидный устойчивый и позитивный бренд проекта и
                  объекта.
                </p>
              </li>
              <li>
                Визуальная концепция объекта. Восприятие объекта в целом.
                Функционально планировочные и технологические решения и
                инновации. Оборудование и оснащение объекта. Разработка и
                применение современных методик и разработок в различных
                подразделениях объекта. Создание последовательного полного цикла
                объекта оздоровления и велнеса.
              </li>
              <li>
                Финансовая концепция предприятия на основе исходных и открытых
                данных. Модель управления предприятием с учетом современных
                информационных и операционных систем.
              </li>
            </ul>
          </div>
        </div>
        <ConceptsBlock />
      </div>
    </section>
  );
};

export { routeMain };
export default ConceptPage;
