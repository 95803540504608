import React from "react";
import { BrowserRouter } from "react-router-dom";

import AppContent from "components/AppContent";
import { AppProvider } from "components/AppContext/AppContext";

import "global/styles/global.scss";
import styles from "./styles.module.scss";

const App = () => {
  return (
    <AppProvider>
      <BrowserRouter>
        {/* <div className={styles.appWrapper}> */}
        <AppContent />
        {/* </div> */}
      </BrowserRouter>
    </AppProvider>
  );
};

export default App;
