import ServiceLine from "./ServiceLine";
import { routeMain as routeArchitecturePage } from "pages/ArchitecturePage";
import { routeMain as routeСonceptPage } from "pages/СonceptPage";

import styles from "./styles.module.scss";

const ServicesSection = () => {
  return (
    <section className={styles.root}>
      {/* <div className={styles.container}> */}
      <div className="container">
        <div className={styles.wrapper}>
          <h2 className={styles.services}>УСЛУГИ</h2>
          <div className={styles.kinds}>
            <ServiceLine
              tip="АРХИТЕКТУРА"
              description="Создание концепции"
              link={routeArchitecturePage}
            />
            <ServiceLine
              tip="КОНЦЕПЦИЯ"
              description="Комплексное проектирование велнес, фитнес и объектов
                здравоохранения"
              link={routeСonceptPage}
              className={styles.line}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
