import { useContext, useEffect, useState } from "react";

import routeMain from "./routes";
import Tree from "pages/MainPage/PortfolioSection/Three";
// import Spiner from "components/ServiceСomponents/Spiner";
import { AppContext } from "components/AppContext/AppContext";

import { fetchData } from "api/api";

import styles from "./styles.module.scss";

const ArchitecturePage = () => {
  const { projects, setProjects } = useContext(AppContext);
  const [projectGroups, setProjectGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      const rezult = await fetchData();
      if (rezult) {
        setProjects(rezult);
      }
    };

    if (projects.length === 0) {
      fetchProjects();
    } else {
      const groups = [];
      for (let i = 0; i < projects.length; i += 3) {
        groups.push(projects.slice(i, i + 3));
      }
      setProjectGroups(groups);
      setIsLoading(false);
    }
  }, [projects, setProjects]);

  return isLoading ? (
    // <Spiner />
    <></>
  ) : (
    <section className={styles.root}>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.headBlock}>
            <p className={styles.aboveTitle}>НАШИ ВЫПОЛНЕННЫЕ РАБОТЫ</p>
            <h1 className={styles.title}>КОМПЛЕКСНОЕ ПРОЕКТИРОВАНИЕ</h1>
          </div>
          {projectGroups.map((item, index) => (
            <Tree key={index} list={item} index={index} />
          ))}
          {/* <EvenTree list={projectGroups[0]} onClick={handleClick} /> */}
        </div>
      </div>
    </section>
  );
};

export { routeMain };
export default ArchitecturePage;
