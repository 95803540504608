import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import close from "assets/img/close.svg";
import styles from "./styles.module.scss";

const NOOP = () => {};

const ModalWindow = ({ children, onClose = NOOP }) => {
  const modalRef = useRef(null);
  useEffect(() => {
    function handleClick(event) {
      const clickedElement = event.target;

      if (
        clickedElement instanceof Node &&
        !modalRef.current?.contains(clickedElement)
      ) {
        onClose();
      }
    }

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [onClose]);

  const node = document.querySelector("#modal_root");
  if (!node) return null;

  return ReactDOM.createPortal(
    <div className={styles.modalOverlay}>
      <div
        className={styles.modalWindow + styles.activemodalWindow}
        ref={modalRef}
      >
        <button className={styles.btnExit} onClick={onClose}>
          <img src={close} alt="Закрыть" />
        </button>
        {children}
      </div>
    </div>,
    node
  );
};

export default ModalWindow;
