import logo_gold from "assets/img/logo_Gold_letters.svg";
import styles from "./styles.module.scss";

const Logotip = () => {
  const logo = logo_gold;

  return <img className={styles.logo} src={logo} alt="Логотип" />;
};

export default Logotip;
