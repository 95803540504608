import styles from "./styles.module.scss";

const Card = ({
  id,
  src,
  stylesPict,
  alt,
  onClick,
  title,
  description,
  index,
}) => {
  return (
    <div>
      <picture>
        {/* <source srcSet={link2_1920} media="(max-width: 1280px)" />
            <source srcSet={link2_1280} media="(max-width: 800px)" /> */}
        <img
          data-id={id}
          onContextMenu={(e) => e.preventDefault()}
          src={src}
          className={`${
            stylesPict === "smol" ? styles.smolImg : styles.bigImg
          }`}
          alt={alt}
          onClick={onClick}
        />
      </picture>

      <div
        className={`${styles.bottomCard} ${
          index % 2 !== 0 ? styles.bottomCardReverse : ""
        }`}
      >
        <div>
          <h3 className={`${styles.coment1} ${styles.architectureSize}`}>
            {title}
          </h3>
          <p className={styles.coment2}>{description}</p>
        </div>
        {window.location.pathname === "/architecture" && (
          <button data-id={id} className={styles.inDetail} onClick={onClick}>
            Подробнее
          </button>
        )}
      </div>
    </div>
  );
};

export default Card;

{
}
