import routeMain from "./routes";
import { useEffect, useRef, useState } from "react";
import aboutTopJPG from "assets/img/aboutPage/about_top_1920.jpg";
import aboutTopAVIF from "assets/img/aboutPage/about_top_1920.avif";
import aboutTopWEBP from "assets/img/aboutPage/about_top_1920.webp";
import aboutBottomJPG from "assets/img/aboutPage/about_bottom_1920.jpg";
import aboutBottomAVIF from "assets/img/aboutPage/about_bottom_1920.avif";
import aboutBottomWEBP from "assets/img/aboutPage/about_bottom_1920.webp";
import styles from "./styles.module.scss";
import Persons from "./Persons";

const AboutPage = () => {
  const containerRef = useRef(null);
  const [offset, setOffset] = useState("0px");

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const { left, right } = containerRef.current.getBoundingClientRect();
        setOffset({ left, right });
      }
    };
    handleResize(); // Вызываем функцию при монтировании компонента
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section
      className={styles.root}
      style={{
        "--distance-to-the-container-far": `${offset.right}px`,
        "--distance-to-the-container": `${offset.left}px`,
        "--width-container": `${offset.right - offset.left}px`,
        "--distance-to-the-container-double": `${offset.left * 2}px`,
      }}
    >
      <div ref={containerRef} className="container">
        <div className={styles.performance1}>
          <h1 className={styles.title1}>
            <span>ИДЕИ РЕШЕНИЯ</span>
            <em className={styles.essence}>РЕАЛИЗАЦИЯ</em>
          </h1>

          <p className={styles.epilogue1}>
            Комплексное проектирование и формирование курортов, объектов
            оздоровления, восстановления и активного долголетия
          </p>
        </div>
      </div>
      <picture>
        <source type="image/webp" srcset={aboutTopWEBP} />
        <source type="image/avif" srcset={aboutTopAVIF} />
        <img
          onContextMenu={(e) => e.preventDefault()}
          src={aboutTopJPG}
          className={styles.aboutFrameRight}
          alt="Помещение"
        />
      </picture>

      <div className="container">
        {/* <div className={styles.performance2}> */}
        <h2 className={styles.title2}>
          ЭКСПЕРТНОСТЬ<em className={styles.essence}>ЭФФЕКТИВНЫЕ РЕШЕНИЯ</em>
        </h2>
        {/* </div> */}
      </div>
      <picture>
        <source type="image/webp" srcset={aboutBottomWEBP} />
        <source type="image/avif" srcset={aboutBottomAVIF} />
        <img
          onContextMenu={(e) => e.preventDefault()}
          src={aboutBottomJPG}
          className={styles.aboutFrameLeft}
          alt="Бассейн"
        />
      </picture>
      <div className="container">
        <div className={styles.performance3}>
          <p className={styles.epilogue3}>
            Мы предлагаем заказчикам максимально короткий путь к реализации их
            задачи на основании создания бизнес концепции, визуального решения
            объекта и его комплексного проектирования
          </p>

          <ul className={styles.list3}>
            <li className={styles.item3}>
              Маркетинг объекта и формирование его бизнес концепции и модели
              управления
            </li>
            <li className={styles.item3}>
              Визуальная концепция и технологические решения будущего велнес
              объекта
            </li>
            <li className={styles.item3}>
              Комплексное проектирование, включая специализированные и требуемые
              технологии
            </li>
            <li className={styles.item3}>
              Создание стильного бренда бизнеса и объекта
            </li>
            <li className={styles.item3}>
              Полное сопровождение проекта при необходимости
            </li>
          </ul>
        </div>
        <Persons />
      </div>
    </section>
  );
};

export { routeMain };
export default AboutPage;
