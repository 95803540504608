import routeMain from "./routes";
import "./styles.module.scss";

const NotFoundPage = () => {
  return (
    <section>
      <h1>Страница не найдена</h1>
    </section>
  );
};

export { routeMain };
export default NotFoundPage;
