import styles from "./styles.module.scss";

const Person = ({ unit }) => {
  return (
    <a href="https://t.me/story_prowellness" target="blank">
      <div className={styles.person}>
        <img
          onContextMenu={(e) => e.preventDefault()}
          src={unit.photo}
          alt={`${unit.firstName} ${unit.lastName}`}
          className={styles.photo}
        />
        <div className={styles.description}>
          <h3 className={styles.fio}>
            {unit.firstName} <br /> {unit.lastName}
          </h3>
          <ul className={styles.list}>
            {unit.li.map((item, index) => (
              <li key={index} className={styles.item}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </a>
  );
};

export default Person;
